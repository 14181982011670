/**
 * main.js
 *
 * @author J.Hahn <j.hahn@agentur-halma.de>
 * @package EIP
 * @version 2017-02-10
 */

/*
 * TODO: comment!!!
 */
EIP = {


	scrollSpyLogoOffset: 0,

	flickityOptions : {
		cellAlign: 'left',
		contain: true,
		pageDots: false
	},

	initialize: function() {
		this.initBasics();
		$(document).ready(this.init.bind(this));

		this.scrollSpyLogoOffset = jQuery('.main-content').offset().top - 10;
	},


	/**
	 * function init()
	 * General initialization method after a page is loaded
	 *
	 * @param {Object} ev jQuery event object
	 */
	init: function(ev) {
		this.initLazyLoading();
		this.initStageSlider();
		this.initScrollSpy();
		// this.initHyphenator();
		this.initCardLinks();
		this.initMap();
		// TODO: Move somewhere else
		var trigger = document.getElementById('trigger');
		document.getElementById('trigger').addEventListener('change', function(ev) {
			document.querySelector('.logo-container').classList.toggle('logo-container--is-small', this.checked);
		});
	},

	/**
	 * function initBasics()
	 * Place to init essential Javascript things. Is executed right after the var CMTLayout is created (before page load)
	 *
	 * @param void
	 * @return void
	 */
	initBasics: function() {

		// bind the functions scope to 'scope'
		Function.prototype.bindScope = function(scope) {

			var _function = this;

			return function() {
				return _function.apply(scope, arguments);
			};
		};
	},

	initScrollSpy: function() {

		$(window).on('scroll', function(ev) {
			if (jQuery(document).scrollTop() > this.scrollSpyLogoOffset) {
				jQuery('body').addClass('scrolled');
				jQuery('.logo-container').addClass('logo-container--is-small');
			} else {
				jQuery('body').removeClass('scrolled')
				if (!document.getElementById('trigger').checked) {
					jQuery('.logo-container').removeClass('logo-container--is-small');
				}
			}
		}.bindScope(this))

		$(window).trigger('scroll');
	},

	// initHyphenator: function() {
	// 	Hyphenator.config({
	// 		minwordlength : 4
	// 	});
	//     Hyphenator.run();
	// },

	initLazyLoading: function() {
/*
		if (typeof $.fn.unveil == 'undefined') {
			return false;
		}

		// Lazy load images
		$('.main-content img[data-src]').unveil(2, function() {
			$(this).load(function() {
				this.style.opacity = 1;
			});
		});

		// Re-trigger on tab switch
		$('.tabs input[type=radio]').change(function() {
			$(window).trigger('lookup');
		});

		return true;
*/
	},

	initStageSlider: function() {

		// $('.stage').owlCarousel(this.owlOptions);
		$('.stage').flickity (this.flickityOptions);

		var captions = document.querySelectorAll('.showcase__title');
	},



	jumpToAnchor: function(anchorName, yOffset) {

		if (!anchorName) {
			anchorName = 'main-content';
		}
		this.scrollTo(jQuery('#' + anchorName), yOffset);
	},

	/**
	 * Scrolls viewport to selected element.
	 *
	 * @param object element
	 * @param integer yOffset optional offset will be added to element's y position.
	 */
	scrollTo: function (element, yOffset) {

		if (typeof yOffset == 'undefined') {
			yOffset = 0;
		}

		$('html, body').animate({ scrollTop: ($(element).offset().top) - parseInt(yOffset)}, 'slow');
	},

	/**
	 * Make (whole) cards clickable if they contain a .link
	 */
	initCardLinks: function() {
		var $cards = $('.card');
		$cards.each(function(i, el) {

			var $card = $(el);
			var $link = $(this).find('.link--is-default-action');

			if ($link.length > 0 && $link.attr('href').length > 0) {
				var linkURL = $link.attr('href');

				$card.css('cursor', 'pointer');
				$card.on('click', function(event) {
					event.preventDefault();
					event.stopPropagation();
					window.location = linkURL;
					return false;
				});
			}
		});
	},

	initMap: function() {
		if (document.getElementById('map') != null) {
			mapInit();
		}

		function mapInit() {
			var EIPLatLng = [9.19024, 48.77873];
			var mapEl = document.getElementById('map');

			var style = 'mapbox://styles/mapbox/streets-v11';

			mapboxgl.accessToken = 'pk.eyJ1IjoiaGFubmVueiIsImEiOiJPMktpVm1BIn0.qMq_8uPobOFc-eBXIFVtog';
			var map = new mapboxgl.Map({
				container: mapEl,
				style: style, // stylesheet location
				center: EIPLatLng, // starting position [lng, lat]
				zoom: 13, // starting zoom
				interactive: false,
			});

			var popUp = document.getElementById('js-popup-content').innerHTML;

			var popup = new mapboxgl.Popup({ closeOnMove: false, offset: 41, focusAfterOpen: false })
			.setLngLat(EIPLatLng)
			.setHTML(popUp)
			.addTo(map)

			var icon = document.createElement('div');
			icon.innerHTML = "<img src='/dist/img/marker_icon.svg'/>"

			var marker = new mapboxgl.Marker({element: icon, anchor: 'bottom',})
				.setLngLat(EIPLatLng)
				.setPopup(popup)
				.addTo(map);

			marker.togglePopup();

			var nav = new mapboxgl.NavigationControl();
			map.addControl(nav, 'top-left');

			// Click to activate
			mapEl.addEventListener('click', function() {
				map.dragPan.enable();
				map.dragRotate.enable();
				map.scrollZoom.enable({ around: 'center' });
				map.touchZoomRotate.enable();
				map.touchPitch.enable();
			});

			// Click anywhere to deactivate
			$("body").click(function (event) {
				var target = $(event.target);
				if (!target.parents('#map').length) {
					map.dragPan.disable();
					map.dragRotate.disable();
					map.scrollZoom.disable();
					map.touchZoomRotate.disable();
					map.touchPitch.disable();
				}
			});
		};
	}
}
// start
window.onload = function() {
	EIP.initialize();
};
